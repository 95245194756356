<!--
 * @Author: Akko
 * @Description: 服务端跳转接收页
-->
<template>
    <!-- 联调时打开查看数据 -->
    <!-- <div style="width:600px;margin:0 auto;word-wrap:break-word">
        <p>token => {{ this.token }}</p>
        <p style="margin-top:20px">data => {{ this.data }}</p>
        <p style="margin-top:20px">若成功 5s后自动跳转 <br /> 若失败 复制以上信息</p>
    </div> -->
    <div />
</template>

<script>
import { serLogin, getProduct } from "@/api/workfow.js";
import { sensorsData } from "../utils/utils";
import Vue from "vue";
export default {
    name: "Auth",
    data() {
        return {
            token: "",
        };
    },
    created() {
        localStorage.clear()
        // this.token = this.$route.params.key
        // this.$route.params.key = 'eyJpc3MiOiJodHRwOlwvXC8xNzIuMTguODUuNjM6OTMxMFwvYnVzaW5lc3NcL2p3dFwvZ2VuZXJhdGVUb2tlbiIsImlhdCI6MTYxODQ1NDU4MSwiZXhwIjoxOTc4NDU0NTgxLCJuYmYiOjE2MTg0NTQ1ODEsImp0aSI6IlZJWkJwdWhwdHFsRXdmOHgiLCJzdWIiOjQzLCJwcnYiOiI0MWVmYjdiYWQ3ZjZmNjMyZTI0MDViZDNhNzkzYjhhNmJkZWM2Nzc3Iiwib3JkZXJfaWQiOiIyMDIxMjYyIn0'
        serLogin({
            token: this.$route.params.key,
        }).then((res) => {
            if (res.data.token == null) {
                this.$message.error("获取登录认证失败！");
                return;
            }
            // setTimeout(() => {

            if (res.code === 200) {
                let d = {
                    step: res.data.baseSchedule, //后端返回baseSchedule为当前项目step 0,1,2,3,4,5,6
                    userName: res.data.userName,
                    orderId: res.data.orderId,
                    baseSchedule:res.data.baseSchedule,//个人信息填写到第几步
                    hasFinishOnePro: res.data.step, //后端返回step为该项目个人信息填写是否已经做完 0为没做完,1为做完
                    order_sn: res.data.orderSn,
                    userId: res.data.userId,
                    phone: res.data.phone
                };
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("user", JSON.stringify(d));
                this.$store.commit("user", res.data);

                // 神策埋点
                this.$sensors.bind("identity_client_user_id", res.data.userId + "")
                this.$sensors.bind("$identity_mobile", res.data.phone + "")

                if (res.data.step == 0 || res.data.step === null) {
                    //个人信息没有填完
                    if (res.data.orderId === "" || res.data.orderId === null) {
                        /**
                         * 服务端跳转过来一般是带有 orderId
                         * 如果没有就去项目列表
                         */
                        this.$router.push({
                            path: "/ProjectList",
                        });
                    } else {
                        // 判断当前是优才还是专才
                        getProduct({
                            order_id: res.data.orderId,
                        }).then((ress) => {
                            if (ress.code === 200) {
                                localStorage.setItem(
                                    "YhorderType",
                                    ress.data.product_id.type
                                );
                                localStorage.setItem(
                                    'product',
                                    JSON.stringify(ress.data.product_id)
                                )
                                this.$store.commit("setType");
                                if (ress.data.product_id.type === "优才" || ress.data.product_id.type === "澳门优才") {
                                    // 是优才且没填完
                                    window.location.href = `${process.env.VUE_APP_USERINFO_URL}/Auth/${this.$route.params.key}`
                                    return
                                } else if (
                                    ress.data.product_id.type === "专才"
                                ) {
                                    this.$router.push({
                                        name:
                                            "etep" +
                                            (Number(res.data.baseSchedule) + 1),
                                        params: {
                                            id: res.data.orderId,
                                        },
                                    });
                                } else if (
                                    ress.data.product_id.type === "留学"
                                ) {
                                    this.$router.push({
                                        name:
                                            "ltep" +
                                            (Number(res.data.baseSchedule) + 1),
                                        params: {
                                            id: res.data.orderId,
                                        },
                                    });
                                }else if(ress.data.product_id.type === '小项目-商务'){
                                    console.log('小项目-商务', ress.data)
                                    this.$router.push({
                                        name:
                                            "education" +
                                            (Number(res.data.baseSchedule) + 1),
                                        params: {
                                            id: res.data.orderId,
                                        },
                                    });
                                }
                                else {
                                    console.log('ress.data.product_id.name', ress);
                                    if (ress.data.product_id.name === "新增随迁人员") {
                                         // 新增随迁人员
                                        window.location.href = `${process.env.VUE_APP_USERINFO_URL}/Auth/${this.$route.params.key}`
                                    } else {
                                        // 小项目优才
                                        const smallProductIds = [29, 55, 30, 32, 96] // 这五类产品走新逻辑，其他继续走以前逻辑
                                        if (smallProductIds.includes(ress.data.product_id.id)) {
                                            this.$router.push({
                                                name: `SmallStep${(Number(res.data.baseSchedule) + 1)}`,
                                                params: {
                                                    id: res.data.orderId,
                                                },
                                                query: {
                                                    id: ress.data.product_id.id // 产品ID
                                                }
                                            });
                                        } else {
                                            this.$router.push({
                                                name:
                                                    "ptep" +
                                                    (Number(res.data.baseSchedule) + 1),
                                                params: {
                                                    id: res.data.orderId,
                                                },
                                            });
                                        }
                                    }
                                }
                            }
                        });
                    }
                } else if (res.data.step != 0) {
                    console.log('jin来')
                    //个人信息7步已经填完
                    localStorage.setItem("isList", 0);
                    res.data.baseSchedule === 7
                        ? localStorage.setItem("stepEnd", 1)
                        : localStorage.setItem("stepEnd", 0)
                    this.$store.commit("user", res.data)
                    // 判断当前是优才还是专才
                    getProduct({
                        order_id: res.data.orderId,
                    }).then((ress) => {
                        if (ress.code === 200) {
                            localStorage.setItem(
                                'product',
                                JSON.stringify(ress.data.product_id)
                            )
                            // 随迁
                            if (ress.data.product_id.type == '随迁') {
                                res.data.baseSchedule === 4
                                    ? localStorage.setItem("stepEnd", 1)
                                    : localStorage.setItem("stepEnd", 0);
                            }
                            localStorage.setItem(
                                "YhorderType",
                                ress.data.product_id.type
                            );
                            localStorage.setItem(
                                "YhorderName",
                                ress.data.product_id.name
                            );
                            this.$store.commit("setType");
                            this.$store.commit("setName");
                            this.$router.push({
                                path: "/HandleProgress",
                            });
                        }
                    });
                }
            } else if (res.code === 403) {
                localStorage.clear();
                this.$router.push({
                    path: "/Login",
                });
            }

            // }, 5000)
        });
    },
    methods: {},
};
</script>

<style lang="less" scoped>
</style>
